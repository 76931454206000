<template>
  <div class="configuration-wrapper">
    <div class="configuration-wrapper__title"> Конфигурация </div>

    <partner/>

  </div>
</template>

<script>
import Partner from "./components/partner/Partner";
export default {
  name: "ConfigurationWrapper",
  components: {
    Partner
  }
}
</script>

<style lang="scss" scoped>
.configuration-wrapper {
  &__title {
    font-family: Raleway, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    margin-bottom: 28px;
  }
}
</style>