import {configurationRepository} from "./configuration.repository";
import {notificationsHelper} from "../../../helpers/notifications.helper";

class ConfigurationService {
    async getPartnerInfo() {
        const config = await configurationRepository.getPartnerInfo()
        return config
    }

    async patchPartnerInfo(config) {
        try {
            const response = await configurationRepository.patchPartnerInfo(config)
            notificationsHelper.success('Обновлено')
            return response
        }catch(error){
            console.log(error)
            throw error
        }

    }
}

export const configurationService = new ConfigurationService()