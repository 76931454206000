<template>
  <div class="partner-wrapper" v-loading="loading">
    <div class="partner-wrapper__title">
      Партнерская программа
    </div>
    <form @submit.prevent="patchConfig">
      <form-input v-model="config.url"
                  :validator="$v.config.url"
                  placeholder="URL для отправки событий"/>

      <div class="partner-wrapper__switch">
        <el-switch
            v-model="config.enabled"
            active-color="#4EE1A3"
            inactive-color="#d0d0d0"/>

        <transition appear name="el-fade-in" :duration="100" mode="out-in">
          <span :key="switchStatus">{{ switchStatus }}</span>
        </transition>
      </div>

      <button class="partner-wrapper__save-btn"
              :class="{'partner-wrapper__save-btn--disabled': isValid}"
              @submit="patchConfig">
        Сохранить
      </button>
    </form>
  </div>
</template>

<script>
import FormInput from "../../../../user/components/FormInput";
import {validationMixin} from "vuelidate";
import {url, required} from 'vuelidate/lib/validators'
import {validate} from "../../../../../utils/validators";
import {configurationService} from "../../configuration.service";

export default {
  name: "Partner",
  mixins: [validationMixin],
  components: {
    FormInput
  },
  data() {
    return {
      config: {
        url: '',
        enabled: false
      },
      loading:true
    }
  },
  mounted() {
    configurationService.getPartnerInfo().then((config) => {
      this.config.url = config.url
      this.config.enabled = config.enabled
    }).finally(()=>{
      this.loading = false
    })
  },
  computed: {
    isValid() {
      return this.$v.$error
    },
    switchStatus() {
      return this.config.enabled ? 'Включено' : 'Выключено'
    }
  },
  methods: {
    patchConfig() {
      if (validate(this.$v))
        configurationService.patchPartnerInfo(this.config)
    }
  },
  validations: {
    config: {
      url: {
        url,
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.partner-wrapper {

  &__title {
    font-family: Raleway, sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  &__save-btn {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 222px;

    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    color: #FFFFFF;

    background: #4EE1A3;
    border-radius: 120px;

    cursor: pointer;
    transition: 0.2s;
    &:before {
      content: '';
      position: absolute;
      bottom: 50%;
      left: 0;
      width: 100%;
      height: 0;
      background: rgba(255, 255, 255, 0.1);
    }

    &:hover:before {
      transition: 400ms;
      bottom: 0;
      height: 100%;
    }

    &--disabled {
      background: #C4C4C4;
      cursor: not-allowed;
    }
  }

  &__switch {
    display: flex;
    align-content: center;
    margin-bottom: 24px;

    span {
      font-family: Raleway, sans-serif;
      font-size: 14px;
      font-weight: 400;
      margin-left: 16px;
    }
  }

  .form-input {
    margin-bottom: 24px;
    width: 50%;
  }
}
</style>