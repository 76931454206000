import http from "../config/admin-axios.config";

class ConfigurationRepository {
    async getPartnerInfo() {
        const response = await http.get('/config/webhook')
        return response.data
    }
    async patchPartnerInfo(config) {
        const response = await http.patch('/config/webhook', config)
        return response.data
    }

}

export const  configurationRepository = new ConfigurationRepository()